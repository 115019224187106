<template>
  <page-template>
    <main-block>
      <block-head title="Leads" :description="$t('Total')+' '+pagination.totalRecords+' leads.'"></block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>
                  <div class="form-inline flex-nowrap gx-3">
                    <div class="form-wrap w-150px">
                      <select class="form-select form-select-md form-control" v-model="bulkSelectedAction">
                        <option value="">{{ $t('Bulk Action') }}</option>
                        <option value="to_marketplace">To Marketplace</option>
                        <option value="delete">{{ $t('Delete') }}</option>
                      </select>
                    </div>
                    <div class="btn-wrap">
                          <span class="d-none d-md-block">
                            <nk-button dim outline type="light" v-on:click="bulkAction" :disabled="!bulkSelectedAction.length">{{ $t('Apply') }}</nk-button>
                          </span>
                      <span class="d-md-none">
                            <nk-button dim outline type="light" v-on:click="bulkAction" is-icon-only-button="" :disabled="!bulkSelectedAction.length">
                              <nio-icon icon="ni-arrow-right"></nio-icon>
                            </nk-button>
                          </span>
                    </div>
                  </div>
                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="advisors_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button=""
                                   class="search-toggle"
                                   v-on:click="searchBarActive=true"
                                   :class="{active: searchBarActive}">
                          <nio-icon icon="ni-search"></nio-icon>
                        </nk-button>
                      </li>
                      <li class="btn-toolbar-sep"></li>
                      <li>
                        <drop-down
                            icon="ni-filter-alt"
                            size="lg"
                            direction="right"
                            :dot="( statusFilter !== '' || mandateFilter !== '' ? 'primary':'')"
                            :title="$t('Advisor')+' '+$t('Filter')">
                          <template v-slot:body>
                            <row class="gx-6 gy-3">
                              <column default="12" md="6">
                                <label class="overline-title overline-title-alt" for="mandate_filter">{{ $t('Mandate') }}</label>
                                <select class="form-control form-control-sm" id="mandate_filter" v-model="mandateFilter">
                                  <option value="">{{ $t('All') }}</option>
                                  <option value="1">{{$t('Signed')}}</option>
                                  <option value="0">{{$t('Not Signed')}}</option>
                                </select>
                              </column>
                              <column default="12" md="6">
                                <label class="overline-title overline-title-alt" for="status_filter">{{$t('Status')}}</label>
                                <select class="form-control form-control-sm" id="status_filter" v-model="statusFilter">
                                  <option value="">{{$t('All')}}</option>
                                  <option v-for="status in leadStatuses" :key="'ls'+status.id" :value="status.id">{{ $t(status.status_text) }}</option>
                                </select>
                              </column>
                            </row>
                          </template>
                          <template v-slot:footer>
                            <nk-button type="dark" dim v-on:click="getLeads(true)">{{$t('Filter')}}</nk-button>
                            <a class="clickable" href="javascript:;" v-on:click="[statusFilter=mandateFilter='',getAdvisors(true)]">{{ $t('Reset Filter') }}</a>
                          </template>
                        </drop-down>
                      </li><!-- li -->
                      <li>
                        <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                          <ul class="link-check">
                            <li><span>{{ $t('Order') }}</span></li>
                            <li :class="{active: order === 'asc'}"><a href="javascript:;" v-on:click="[order='asc', getLeads(true)]">{{ $t('ASC') }}</a></li>
                            <li :class="{active: order === 'desc'}"><a href="javascript:;" v-on:click="[order='desc', getLeads(true)]">{{ $t('DESC') }}</a></li>
                          </ul>
                        </drop-down>
                      </li><!-- li -->
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
              <card-search
                  :placeholder="$t('Search')+' '+$t('advisor')"
                  :is-active="searchBarActive"
                  @do-search="searchAdvisors"
                  :search-term="searchFilter"
                  @close-search="[searchBarActive=false, searchAdvisors('')]"></card-search>
            </div>
            <div class="d-flex py-5 justify-center"  v-if="loadingData">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <ion-list v-if="$isHybridPlatform">
                  <template v-for="lead in leads" :key="'ld'+lead.id">
                    <ion-item button
                              v-on:click="leadActionSheet(lead)"
                              lines="full" style="--border-color:#e3eaf5;--padding-bottom: 8px;--padding-top: 8px;">
                      <ion-avatar slot="start" class="user-avatar">
                        <img v-if="lead.dp" :src="lead.dp" alt="Avatar"/>
                        <span v-else class="text-uppercase">{{ lead.initials }}</span>
                      </ion-avatar>
                      <div>
                        <ion-label class="fw-500" color="dark">{{ lead.first_name+' '+lead.last_name}}</ion-label>
                        <ion-text color="soft" class="medium d-block">#{{ lead.lead_number }}</ion-text>
                        <ion-text color="soft" class="medium d-block">{{ lead.received_time }}</ion-text>
                        <ion-text color="soft" class="medium d-block">{{ lead.post_code+', '+lead.city+','+lead.province }}</ion-text>
                      </div>
                      <template v-if="lead['66analytics_initial_source']">
                        <ion-badge color="soft" slot="end" v-if="lead['66analytics_initial_source'].indexOf('facebook') !== -1">
                          <nio-icon icon="ni-facebook-f" color="#fff"></nio-icon>
                        </ion-badge>
                        <ion-badge color="soft" slot="end" v-else-if="lead['66analytics_initial_source'].indexOf('google') !== -1">
                          <nio-icon icon="ni-google" color="#fff"></nio-icon>
                        </ion-badge>
                      </template>
                    </ion-item>
                  </template>
                </ion-list>
                <special-table v-else>
                  <table-row is-heading>
                    <table-col has-check-box>
                      <b-check-box id="ll_select_all" v-model="selectAll"></b-check-box>
                    </table-col>
                    <table-col break-point="md">
                      <span class="sub-text">Lead#</span>
                    </table-col>
                    <table-col>
                      <span class="sub-text">Lead</span>
                    </table-col>
                    <table-col break-point="mb">{{ $t('Address') }}</table-col>
                    <table-col break-point="mb">{{ $t('Source') }}</table-col>
                    <table-col break-point="md">{{ $t('Received')+' '+$t('on') }}</table-col>
                    <table-col break-point="lg">{{ $t('Phone') }}</table-col>
                    <table-col class="nk-tb-col-tools"></table-col>
                  </table-row>

                  <table-row v-for="lead in leads" :key="'ld'+lead.id">
                    <table-col has-check-box>
                      <b-check-box :id="'lead'+lead.id" v-model="lead.selected" :key="'cb'+lead.id"></b-check-box>
                    </table-col>
                    <table-col break-point="md">{{ lead.lead_number }}</table-col>
                    <table-col>
                      <user-small-card
                          :user-name="lead.first_name+' '+lead.last_name"
                          :user-email="lead.email" :image="lead.dp" :initials="lead.initials">
                        <template v-slot:more_info v-if="!isDesktop">
                          <div class="small lh-n"><b>#</b> {{lead.lead_number}}<br/>
                            <b>{{ $t('Received')}}:</b> {{ lead.received_time }}<br/>
                            <b>{{ $t('City')}}:</b> {{ lead.city }}
                          </div>
                        </template>
                      </user-small-card>
                    </table-col>
                    <table-col break-point="mb">
                      <span>{{ lead.house_no+', '+lead.street }}</span>
                      <span class="tb-lead">{{ lead.post_code+', '+lead.city+','+lead.province }}</span>
                    </table-col>
                    <table-col break-point="mb">
                      <template v-if="lead['66analytics_initial_source']">
                        <nio-icon icon="ni-facebook-f" v-if="lead['66analytics_initial_source'].indexOf('facebook') !== -1"></nio-icon>
                        <nio-icon icon="ni-google" v-if="lead['66analytics_initial_source'].indexOf('google') !== -1"></nio-icon>
                        <span v-else>{{ lead['66analytics_initial_source'] }}</span>
                      </template>
                      <span v-else>--</span>
                    </table-col>
                    <table-col break-point="md">{{ lead.received_time }}</table-col>
                    <table-col break-point="lg">{{ lead.phone_no }}</table-col>
                    <table-col class="nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <template v-if="isDesktop">
                          <table-action icon="ni-forward-arrow-fill"
                                        :to="{name: 'adminLeadDetails', params:{id:lead.id}}"
                                        v-tooltip="'Assign'"></table-action>
                          <table-action icon="ni-trash-fill" v-on:click="F_deleteRecords([lead])" v-tooltip="$t('Delete')"></table-action>
                          <table-action icon="ni-cart-fill" v-on:click="F_addToMarketplace([lead])" v-tooltip="'To marketplace'"></table-action>
                        </template>
                        <li>
                          <drop-down-list>
                            <list-item icon="ni-eye" :text="$t('View Details')" :to="{name: 'adminLeadDetails', params:{id:lead.id}}"></list-item>
                            <template v-if="!isDesktop">
                              <list-item icon="ni-forward-arrow-fill" :text="$t('Assign')" :to="{name: 'adminLeadDetails', params:{id:lead.id}}"></list-item>
                              <list-item icon="ni-trash-fill" :text="$t('Delete')" v-on:click="F_deleteRecords([lead])"></list-item>
                              <list-item icon="ni-cart-fill" :text="$t('To marketplace')" v-on:click="F_addToMarketplace([lead])"></list-item>
                            </template>
                          </drop-down-list>
                        </li>
                      </ul>
                    </table-col>
                  </table-row>

                </special-table>

              </div>
              <div class="card-inner" v-if="pagination.totalRecords > 10">
                <pagination
                    v-model="pagination.page"
                    :records="pagination.totalRecords"
                    :per-page="10"
                    @paginate="paginationCallback"
                    :options="{chunk: 5, texts:{count: '||'}}"/>
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {actionSheetController, IonSpinner, isPlatform, onIonViewWillEnter,IonList,
  IonItem,
  IonAvatar,
  IonLabel,
  IonText, IonBadge} from '@ionic/vue';
import {defineComponent, reactive, ref, watch} from 'vue';
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import axios from "@/libs/axios"
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";

import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import DropDown from "@core/components/dropdown/DropDown";

import { Column, Row } from "@core/layouts"

import Pagination from "v-pagination-3"
import BlockHead from "@core/layouts/main-block/components/BlockHead";
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import BCheckBox from "@core/components/bp-form/components/BCheckBox";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import TableAction from "@core/components/special-table/components/TableAction";
import DropDownList from "@core/components/dropdown-list/DropDownList";
import ListItem from "@core/components/dropdown-list/ListItem";
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import useMarketplace from "./useMarketplace";
import useDeleteLeads from "@/views/admin/Leads/useDeleteLeads";
import useDataChangeTracker from "@/store/data-change-tracker/dataChangeTracker"
import {useI18n} from "vue-i18n";
import PageTemplate from "@core/layouts/page/PageTemplate";


export default defineComponent({
  components: {
    PageTemplate,
    ListItem,
    DropDownList,
    TableAction,
    UserSmallCard,
    BCheckBox,
    SpecialTable,
    TableCol,
    TableRow,
    CardSearch,
    CardTools,
    BlockHead,
    Column,
    Row,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    NkButton,
    NioIcon,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    IonSpinner,
    Pagination,
    IonList,
    IonItem,
    IonAvatar,
    IonLabel,
    IonText,
    IonBadge,
  },
  setup() {

    const isDesktop = isPlatform('desktop')
    const router  = useRouter()
    const store   = useStore()
    const { updateTracker }     = useDataChangeTracker()
    const leadStatuses = store.state.general_data.leads_statuses
    const {t} = useI18n()

    let searchFilter  = ref('');
    let statusFilter  = ref('')
    let mandateFilter = ref('')
    let order         = ref('asc')

    let loadingData = ref(false)

    let selectAll    = ref(false)

    let leads     = ref([])

    let searchBarActive = ref(false)

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    const paginationCallback = async () => {
      await getLeads(true)
    }

    let cancelToken = false
    const getLeads = async (reset) => {

      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      loadingData.value = true
      axios.post('/api/admin/leads/list', {
            page: pagination.page,
            status: statusFilter.value,
            search: searchFilter.value,
            order: order.value
          },
          {
            cancelToken: new axios.CancelToken( c => {
              cancelToken = c
            })
          })
          .then( (resp) => {
            if(reset){
              leads.value = []
            }
            for(let x in resp.data.data){
              resp.data.data[x].selected = false;
              leads.value.push(resp.data.data[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            console.log('Invoices.vue ajax error: ', err)
          })
          .then(() => {
            cancelToken = false
            loadingData.value = false
          })
    }

    watch(searchFilter, () => getLeads(true))

    const searchAdvisors = (search) => {
      searchFilter.value = search
    }

    onIonViewWillEnter(()=> getLeads(true))

    watch(selectAll, (n) => {
      for(let x in leads.value){
        leads.value[x].selected = n
      }
    })

    const {deleteRecords} = useDeleteLeads()
    const F_deleteRecords = async ( _records ) => {

      deleteRecords(_records, 'lead',(success) => {
        if(success){
          updateTracker('admin', ['leads_list'])
          // Remove the deleted entries from the advisors array
          for(let x in _records){
            for(let y in leads.value){
              if(leads.value[y].id === _records[x].id){
                leads.value.splice(y,1)
                break;
              }
            }
          }
          // All deleted, no entry left in the advisor array? and previous pages exists
          if( !leads.value.length && pagination.page > 1 ){
            // Deduct from total records value
            pagination.totalRecords -= _records.length
            // Goto previous page
            pagination.page--
            getLeads(true)
          }
        }
      })
    }

    const { addToMarketplace } = useMarketplace()
    const F_addToMarketplace = ( _leads ) => {

      let ids = []
      for(let x in _leads){
        ids.push( _leads[x].id)
      }
      addToMarketplace(_leads, (success ) => {
        if(success){
          updateTracker('admin', ['marketplace'])
          // Remove the selected leads from the leads array
          for(let x in ids){
            for(let y in leads.value){
              if(leads.value[y].id === ids[x]){
                leads.value.splice(y,1)
                break;
              }
            }
          }
          // All deleted, no entry left in the advisor array? and previous pages exists
          if( !leads.value.length && pagination.page > 1 ){
            // Deduct from total records value
            pagination.totalRecords -= ids.length
            // Goto previous page
            pagination.page--
            getLeads(true)
          }
        }
      })
    }

    let bulkSelectedAction = ref('')
    const bulkAction = async () => {
      let selectedRecords = []
      for(let x in leads.value){
        if( leads.value[x].selected){
          selectedRecords.push( leads.value[x])
        }
      }
      if( selectedRecords.length < 1 ){
        return false
      }
      if( bulkSelectedAction.value === 'delete' ){
        await F_deleteRecords(selectedRecords)
      }
      else if( bulkSelectedAction.value === 'to_marketplace'){
        F_addToMarketplace( selectedRecords )
      }
    }

    const leadActionSheet = async (lead) => {
      const actionSheet = await actionSheetController.create({
        header: t('Choose an action'),
        buttons: [
          {
            text: t('View details'),
            handler: ()=> router.push({name: 'adminLeadDetails', params:{id:lead.id}})
          },
          {
            text: t('Assign'),
            handler: ()=> router.push({name: 'adminLeadDetails', params:{id:lead.id}})
          },
          {
            text: t('To marketplace'),
            handler: ()=> F_addToMarketplace([lead])
          },
          {
            text: t('Delete'),
            role: 'destructive',
            handler:()=> F_deleteRecords([lead])
          },
        ],
      });
      await actionSheet.present();
    };

    // Update leads list, in case a lead get changed in other components
    watch(() => store.state.dataChangeTracker.admin.leads_list, (n) => {
      if(n){
        updateTracker('admin', ['leads_list'], false) // reset tracker
        getLeads(true)
      }
    })

    return {
      isDesktop,
      leadActionSheet,
      leadStatuses,
      leads,
      getLeads,
      loadingData,
      router,


      searchAdvisors,

      mandateFilter,
      searchFilter,
      statusFilter,
      order,
      pagination,
      paginationCallback,

      searchBarActive,
      selectAll,

      bulkSelectedAction,
      bulkAction,
      F_deleteRecords,
      F_addToMarketplace,
    }
  }
});

</script>
